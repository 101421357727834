<template>
    <div class="phone-upload-error">
<!--        <i-close-one theme="filled" size="44" fill="#db311d"/>-->
        <img src="../../assets/edit-imgs/upload/upload-warn.png" class="icon"/>
        <div class="tip">{{$t('upload.error.info')}}</div>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {

            }
        },
        mounted() {
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";

    .phone-upload-error{
        width: 100%;
        height: 100%;
        flex-direction: column;
        .flex-center;
        .tip{
            margin-top: 0.05rem;
            font-size: 0.04rem;
        }
        .icon{
            width: 0.155rem;
            height: 0.155rem;
        }
    }
</style>
