<template>
    <div class="phone-upload-images">
        <div class="images">
            <div class="image" v-for="(r,idx) in Requests">
                <img class="file" :src="getUrl(r.file)"/>
                <img src="../../assets/edit-imgs/upload/del-icon.png" class="delete" @click="deleteFile(idx)" v-if="r.status !== STATUS.UPLOADING"/>
                <div class="mask" v-if="r.status !== STATUS.WAIT">
                    <div class="circle-view">
                        <el-progress type="circle" :percentage="r.percentage" color="#FF6F33" :show-text="false"></el-progress>
                    </div>
                </div>
            </div>
        </div>
        <div class="buttons">
            <div class="button delBtn" @click="pickerImage">
                <input class="input"
                       type="file"
                       multiple
                       accept="image/*"
                       ref="input"
                       @change="loadImages">
                {{$t('upload.continueAdding')}}
            </div>
            <div class="button" @click="beginUpload">{{$t('upload.startUploading')}}</div>
        </div>
    </div>
</template>

<script>
    import {UPLOAD} from "../../request/request";
    import lrz from "lrz"

    const  STATUS = {WAIT:'warning',EXCEPTION:'exception',SUCCESS:'success',UPLOADING:null};
    export default {
        name: "index",
        data() {
            return {
                STATUS:STATUS,
                Requests:[],
                accept:'*',
                size:-1,
                uploading:false,
                token:null,
            }
        },
        mounted() {
            this.token = this.$route.query.t;
        },

        methods: {
            pickerImage(){
                this.$refs.input.click();
            },
            loadImages(e){
                const files = e.target.files;
                this.appendFiles(files);
            },

            //判断类型
            checkAccept(file){
                return true;
            },
            //判断大小
            checkSize(file){
                return true;
            },
            //file转url
            getUrl(file){
                return window.URL.createObjectURL(file);
            },
            deleteFile(idx){
                this.Requests.splice(idx,1);
            },
            percentageStyle(r){
                let style = {
                    width:`${r.percentage}%`,
                };
                if (r.status === STATUS.SUCCESS){
                    style['background'] = '#67C23A';
                }
                if (r.status === STATUS.EXCEPTION){
                    style['background'] = '#F56C6C';
                }

                return style;
            },
            appendFiles(files){
                if (files.length === 0) return;

                files.forEach(f=>{
                    if (!this.checkAccept(f)){
                        this.Requests.push({
                            file:f,
                            status:STATUS.EXCEPTION,
                            message:this.$t('fileUpload.typeUnsupported',{type:this.accept}),
                            percentage:100,
                        });
                    }else if (!this.checkSize(f)){
                        let size = `${(this.size / 1024 / 1024).toFixed(1)}M`;
                        this.Requests.push({
                            file:f,
                            status:STATUS.EXCEPTION,
                            message:this.$t('fileUpload.sizeUnsupported',{size:size}),
                            percentage:100,
                        });
                    }else {
                        this.Requests.push({
                            file:f,
                            status:STATUS.WAIT,
                            message:this.$t('fileUpload.wait'),
                            percentage:0,
                        });
                    }
                });
            },

            beginUpload(){

                if (this.uploading) return;

                if (this.Requests.length === 0 || !this.Requests.find(r=>r.status === STATUS.WAIT)){
                    this.$message({
                        message: '请先选择文件',
                        type: 'warning'
                    });
                    return;
                }

                this.start();
            },
            start(){
                if (this.uploading) return;

                let waitReqs = this.Requests.filter(r=>r.status === STATUS.WAIT);
                if (waitReqs.length === 0) {
                    this.completeUpload();
                    return;
                }
                let waitReq = waitReqs[0];

                let _this= this;
                waitReq.status = STATUS.UPLOADING;
                if (waitReq.file.size > 1024000){
                    //超过1m 压缩
                    lrz(waitReq.file,{quality:0.4}).then(res=>{
                        waitReq.file = new File([res.file], `${new Date().getTime()}.jpeg`);
                        _this.uploadFile(waitReq,waitReqs.length === 1);
                    }).catch(err=>{
                        console.log(err);
                        waitReq.percentage = 100;
                        waitReq.status = STATUS.EXCEPTION;
                        waitReq.message = err.message;
                        _this.uploading = false;
                        _this.start();
                    })
                }else {
                    this.uploadFile(waitReq,waitReqs.length === 1);
                }
            },

            completeUpload(){
                this.Requests = [];
                this.$emit('complete');
            },
            errorUpload(){
                this.Requests = [];
                this.$emit('error');
            },

            /**
             * @param req
             * @param last 是否最后一个
             */
            uploadFile(req,last){
                let formData = new FormData();
                formData.append("files",req.file);
                let _this = this;

                let headers = {
                    'MaterialToken':this.token,
                    'uploaded':last?'uploaded':false
                };

                return UPLOAD('material/mobile/material/uploadBatch',formData,(res)=>{
                    req.percentage = Math.round(100.0 * res.loaded / res.total);
                },headers).then(res=>{
                    if (res.code === 200){
                        req.percentage = 100;
                        req.status = STATUS.SUCCESS;
                    }else if (res.code === 40000 || res.code === 919){
                        _this.errorUpload();
                    }else {
                        req.percentage = 100;
                        req.status = STATUS.EXCEPTION;
                        req.message = err.message;
                    }
                }).catch(err=>{
                    req.percentage = 100;
                    req.status = STATUS.EXCEPTION;
                    req.message = err.message;
                }).finally(res=>{
                    _this.uploading = false;
                    _this.start();
                })
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";

    .phone-upload-images{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        .images{
            flex: 1;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            grid-gap: 0.03rem;
            padding: 0.03rem;
            overflow-y: scroll;
            height: 1rem;
            align-content: flex-start;
            -webkit-overflow-scrolling: touch;

            .image{
                border-radius: 0.021rem;
                background: #f2f2f2;
                height: 0.293rem;
                width: 0.29rem;
                overflow: hidden;
                .flex-center;
                position: relative;
                .file {
                    max-height: 100%;
                    max-width: 100%;
                    z-index: 0;
                }
                .delete{
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 0.067rem;
                    height: 0.067rem;
                    z-index: 2;
                }
                .mask{
                    z-index: 1;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    background: rgba(0,0,0,0.3);
                    .process{
                        width: 0.064rem;
                        height: 0.064rem;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        margin-top: -0.035rem;
                        margin-left: -0.035rem;
                        border-radius: 50% 50%;
                        background: transparent;
                        border: 0.006rem solid white;
                        animation: spin 2s linear infinite;
                        overflow: hidden;
                        /*.percentage{*/
                        /*    background: #E6A23C;*/
                        /*    position: absolute;*/
                        /*    top: 0;*/
                        /*    left: 0;*/
                        /*    height: 100%;*/
                        /*    border-radius: 0.01rem;*/
                        /*}*/

                    }
                }
            }
        }
        .circle-view{
            width: 0.064rem;
            height: 0.064rem;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -0.035rem;
            margin-left: -0.035rem;
            border-radius: 50% 50%;
            /deep/ .el-progress-circle{
                width: 0.064rem!important;
                height: 0.064rem!important;
            }
        }
        .buttons{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            background: white;
            width: 100%;
            bottom: 0;
            left: 0;
            padding: 0.05rem 0;
            .delBtn{
                background: #fff!important;
                border: 0.001rem solid #CCCCCC!important;
                color: #333333!important;
            }
            .button{
                height: 0.107rem;
                width: 0.448rem;
                background: @color-primary;
                border: 0.001rem solid @color-primary;
                .flex-center;
                color: white;
                border-radius: 0.2rem;
                position: relative;
                font-size: 0.04rem;
                .input{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    opacity: 0;
                    height: 0;
                }
            }
        }
    }
</style>
