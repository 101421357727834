
//1rem = 屏幕宽度
export default function () {
    let rem = document.documentElement.offsetWidth;
    document.documentElement.style.fontSize = `${rem}px`;
    let d = window.document.createElement('div');
    d.style.width = '1rem';
    d.style.display = "none";
    let head = window.document.getElementsByTagName('head')[0];
    head.appendChild(d);
    let defaultFontSize = parseFloat(window.getComputedStyle(d, null).getPropertyValue('width'));
    rem = rem * rem / defaultFontSize;
    document.documentElement.style.fontSize = `${rem}px`;
}
