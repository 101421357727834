<template>
    <div class="phone-upload-file">
        <Empty v-if="status === S.EMPTY" @files="fileChanges"/>
        <Upload ref="upload" v-if="status === S.UPLOAD" @error="status = S.ERROR" @complete="status = S.FINISH"/>
        <Complete v-if="status === S.FINISH"/>
        <Error v-if="status === S.ERROR"/>
    </div>
</template>

<script>
    import AdapterRem from "../../utils/AdapterRem";
    import Empty from "./empty"
    import Upload from "./upload"
    import Complete from "./complete"
    import Error from "./error"

    export default {
        name: "index",
        components:{Empty,Upload,Complete,Error},
        data() {
            return {
                S:{EMPTY:'EMPTY',UPLOAD:'UPLOAD',FINISH:'FINISH',ERROR:'ERROR'},
                status:'EMPTY',
            }
        },
        mounted() {
            AdapterRem();
        },

        methods: {
            fileChanges(files){
                if (files.length === 0) return;
                this.status = this.S.UPLOAD;
                let _this = this;
                this.$nextTick(function () {
                    _this.$refs.upload.appendFiles(files);
                });
            },
        }
    }
</script>

<style scoped>
    .phone-upload-file{
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }
</style>

<style>
    html,body{
        min-width: initial !important;
        min-height: initial !important;
        user-select: none;
        overflow: hidden;
    }
</style>
