<template>
    <div class="phone-upload-empty">
        <img class="icon" src="../../assets/edit-imgs/upload/upload-img.png"/>
        <div class="tip">{{$t('upload.empty.tip')}}</div>
        <div class="button" @click="pickerImage">
            <input class="input"
                   type="file"
                   multiple
                   accept="image/*"
                   ref="input"
                   @change="loadImages">
            <img src="../../assets/edit-imgs/upload/add-img.png" class="addIcon">
            {{$t('upload.empty.uploadPictures')}}
        </div>
    </div>
</template>

<script>
    import AdapterRem from "../../utils/AdapterRem";

    export default {
        name: "index",
        data() {
            return {

            }
        },
        mounted() {
            AdapterRem();
        },

        methods: {

            pickerImage(){
                this.$refs.input.click();
            },
            loadImages(e){
                const files = e.target.files;
                this.$emit('files',files)
            }
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";

    .phone-upload-empty{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon{
            width: 0.291rem;
            height: 0.245rem;
            /*background: lightblue;*/
        }

        .tip{
            opacity: 0.8;
            margin: 0.04rem 0 0;
        }
        .addIcon{
            width: 0.043rem;
            height: 0.043rem;
            margin-right: 0.021rem;
        }

        .button{
            width: 0.4rem;
            height: 0.1rem;
            background: @color-primary;
            color: white;
            margin: 0.085rem 0 0;
            .flex-center;
            font-size: 0.04rem;
            border-radius: 0.05rem;
            position: relative;
            .input{
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                opacity: 0;
                height: 0;
            }
        }
    }
</style>
